import React, { useCallback, useState } from 'react'
import Modal from '@mui/material/Modal'
import config from 'use/Config'
import MrcButton from 'components/MrcButton'
import axiosInstance from 'util/axios'
import ReactLoading from 'react-loading'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

const getFilePath = (docid, contentType) => {
    const isHtml =
        contentType === 'text/html' || contentType === 'application/octet-stream' ? '' : config.ccda.helperUrl
    return isHtml + process.env.REACT_APP_MRC_BASE_URL + '/MergeEHR/DocumentReferenceBinary?docid=' + docid
}

const CcdaHelper = ({ docid, buttonProps, contentType }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [documentDetails, setDocumentDetails] = useState(null)
    const filePath = getFilePath(docid, contentType)
    const getDetails = async () => {
        try {
            if (contentType === 'text/html') {
                const response = await axiosInstance.get(filePath)
                const tempDiv = document.createElement('div')
                tempDiv.innerHTML = response.data
                const textContent = tempDiv.textContent || tempDiv.innerText
                setDocumentDetails({ html: textContent })
                setLoading(false)
            } else if (contentType === 'application/octet-stream') {
                const response = await axiosInstance.get(filePath)
                const textValue = atob(response.data).replace(/\n/g, '<br />')
                setDocumentDetails({ html: textValue })
                setLoading(false)
            } else if (contentType === 'text/plain') {
                const newUrl = `${process.env.REACT_APP_MRC_BASE_URL}/MergeEHR/get-pdf/${docid}`
                const response = await axiosInstance.get(newUrl)
                const textValue = atob(response.data).replace(/\n/g, '<br />')
                setDocumentDetails({ html: textValue })
                setLoading(false)
            } else {
                const newUrl = `${process.env.REACT_APP_MRC_BASE_URL}/MergeEHR/get-pdf/${docid}`
                const response = await axiosInstance.get(newUrl)
                const byteCharacters = atob(response.data)
                const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i))
                const byteArray = new Uint8Array(byteNumbers)
                const blob = new Blob([byteArray], { type: 'application/pdf' })
                const url = URL.createObjectURL(blob)
                setDocumentDetails({ pdf: url })
                setLoading(false)
            }
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    }

    const handleClick = () => {
        setOpen(true)
        if (
            contentType === 'text/html' ||
            contentType === 'application/pdf' ||
            contentType === 'text/plain' ||
            contentType === 'application/octet-stream'
        ) {
            setLoading(true)
            getDetails()
        }
    }

    const handleClose = () => setOpen(false)

    const renderModal = useCallback(() => {
        if (loading)
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <ReactLoading width={20} height={20} type="spokes" color="rgb(64 60 60)" />
                </div>
            )
        if (documentDetails?.html)
            return (
                <div
                    dangerouslySetInnerHTML={{ __html: documentDetails?.html }}
                    style={{ padding: '20px', whiteSpace: 'pre-line' }}
                />
            )
        if (documentDetails?.pdf)
            return (
                <iframe
                    id="dlRetpdfPreview"
                    width="100%"
                    height="100%"
                    title="ccda"
                    src={documentDetails?.pdf ? documentDetails?.pdf : ''}
                />
            )

        return <iframe className="iframe-size" src={filePath} title="ccda" />
    }, [documentDetails?.html, documentDetails?.pdf, filePath, loading])

    return (
        <div>
            <MrcButton startIcon={<VisibilityOutlinedIcon />} onClick={handleClick} {...buttonProps}>
                View document
            </MrcButton>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-size">{renderModal()}</div>
            </Modal>
        </div>
    )
}

export default CcdaHelper
